import request from '@/utils/request'
export function getFeedbackList (data) {
  return request({
    url: '/game/assess/getFeedbackList',
    data
  })
}
export function getFeedbackListV2 (params) {
  return request({
    url: '/admin/feedback/v2',
    method: 'get',
    params,
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function getFeedbackTypes (data) {
  return request({
    url: '/game/assess/getFeedbackTypes',
    data
  })
}
export function setFeedbackTop (data) {
  return request({
    url: '/game/assess/setFeedbackTop',
    data
  })
}
export function forbiddenFeedbackUser (data) {
  return request({
    url: '/game/assess/forbiddenFeedbackUser',
    data
  })
}
export function addFeedbackReply (data) {
  return request({
    url: '/game/assess/addFeedbackReply',
    data
  })
}
export function getUserBasic (data) {
  return request({
    url: '/user/getUserBasic',
    data
  })
}
export function addUserMessage (data) {
  return request({
    url: '/user/addUserMessage',
    data
  })
}
export function batchSendMessage (data) {
  return request({
    url: '/user/batchSendMessage',
    data
  })
}
export function userMessageList (data) {
  return request({
    url: '/user/userMessageList',
    data
  })
}
export function getFeedbackTypeList (params) {
  return request({
    url: 'admin/feedback-type',
    method: 'get',
    params,
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function addFeedbackType (data) {
  return request({
    url: '/admin/feedback-type',
    data,
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function editFeedbackType (id, data) {
  return request({
    url: `/admin/feedback-type/${id}`,
    method: 'put',
    data,
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function delFeedbackType (data) {
  return request({
    url: '/game/assess/delFeedbackType',
    data
  })
}
export function newsList (data) {
  return request({
    url: '/activity/news/list',
    data
  })
}
export function newsDel (data) {
  return request({
    url: '/activity/news/del',
    data
  })
}
export function newsAdd (data) {
  return request({
    url: '/activity/news/add',
    data
  })
}
export function newsEdit (data) {
  return request({
    url: '/activity/news/edit',
    data
  })
}
export function newsGet (data) {
  return request({
    url: '/activity/news/get',
    data
  })
}
export function commonUpload (data) {
  return request({
    url: '/common/upload',
    data
  })
}
export function getFeedbackTypeCharList (data) {
  return request({
    url: '/game/assess/getFeedbackTypeCharList'
  })
}
export function getAllChannelType (data) {
  return request({
    url: '/activity/getAllChannelType',
    data
  })
}
export function addChannelType (data) {
  return request({
    url: '/activity/addChannelType',
    data
  })
}
export function updateChannelType (data) {
  return request({
    url: '/activity/updateChannelType',
    data
  })
}
export function delChannelType (data) {
  return request({
    url: '/activity/delChannelType',
    data
  })
}
